<template>
  <div class="p-grid p-justify-center p-formgrid">
    <div class="p-col-12 p-md-6 p-lg-4">
      <h3>Мой профиль</h3>
      <div class="card p-fluid">
        <form @submit.prevent="submitForm">
          <div class="p-field">
            <label for="name">Имя</label>
            <InputText
              id="name"
              v-model.trim="name.val"
              :required="true"
              @blur="clearValidity('name')"
              :class="!name.isValid ? 'p-invalid' : ''"
              :disabled="!permissions['users']"
            />
          </div>
          <div class="p-field">
            <label for="lastname">Фамилия</label>
            <InputText
              id="lastname"
              v-model.trim="lastname.val"
              :required="true"
              @blur="clearValidity('lastname')"
              :class="!lastname.isValid ? 'p-invalid' : ''"
              :disabled="!permissions['users']"
            />
          </div>
          <div class="p-field">
            <label for="role">Роль</label>
            <InputText id="role" v-model="allRoles[userData.role]" disabled />
          </div>
          <div class="p-field">
            <label for="email">E-mail</label>
            <InputText
              type="email"
              id="email"
              @blur="clearValidity('email')"
              :class="!email.isValid ? 'p-invalid' : ''"
              v-model.trim="email.val"
              :required="true"
              :disabled="!permissions['users']"
            />
          </div>
          <div class="p-field">
            <label for="phone">Телефон</label>
            <InputMask
              mask="+7-999-999-99-99"
              id="phone"
              v-model.trim="phone.val"
              :required="true"
              @blur="clearValidity('phone')"
              :class="!phone.isValid ? 'p-invalid' : ''"
              :autoClear="false"
              :disabled="!permissions['users']"
            />
          </div>
          <div class="p-field" v-if="role === 'OPERATOR'">
            <label for="ephone">Расширенный телефон</label>
            <InputText
              id="ephone"
              v-model.trim="extensionPhone.val"
              :required="true"
              @blur="clearValidity('extensionPhone')"
              :autoClear="false"
            />
          </div>
          <div class="p-field">
            <label for="region">Регион</label>
            <RegionPicker
              v-model="selectedGroupedCities.val"
              :disabled="!permissions['users']"
            />
          </div>
          <div class="p-field" v-if="role === 'SALES_MANAGER'">
            <label for="company">Выберите компании</label>
            <Dropdown
              :filter="true"
              v-model="selectedCompany"
              :options="companies"
              placeholder="Нажмите, чтобы выбрать клиента для привязки к профилю"
              optionLabel="name"
              emptyFilterMessage="Совпадений не найдено"
              :disabled="!permissions['users']"
            >
              <template #option="slotProps">
                <div class="employee-item">
                  <div>
                    {{ slotProps.option.name }}
                  </div>
                </div>
              </template>
            </Dropdown>
          </div>
          <div class="p-field" v-if="role === 'SALES_MANAGER'">
            <label v-if="companiesForProfile?.length">Выбранные компании</label>
            <div
              v-for="(company, index) in companiesForProfile"
              :key="index"
              class="p-d-flex p-ai-center p-jc-between"
            >
              <div>{{ index + 1 }}. {{ company.name }}</div>
            </div>
          </div>
          <Button
            label="Обновить"
            @click="submitForm"
            :disabled="isLoading"
            v-if="permissions['users']"
          ></Button>
        </form>
        <Button
          class="p-mt-3 p-button-warning"
          label="Сменить пароль"
          @click="openConfirm"
          :disabled="isLoading"
        ></Button>
      </div>
    </div>
    <Dialog
      header="Внимание!"
      v-model:visible="displayConfirmation"
      :style="{ width: '350px' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>Вы уверены, что хотите сменить пароль?</span>
      </div>
      <template #footer>
        <Button
          label="Нет"
          icon="pi pi-times"
          @click="closeConfirmation"
          class="p-button-text"
        />
        <Button
          label="Да"
          icon="pi pi-check"
          @click="closeConfirmationAndSelect"
          class="p-button-text"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { environment } from "@/config";
import { ROLES_LIST } from "@/models/roles";
import RegionPicker from "@/components/RegionPicker";

export default {
  name: "UserCreate",
  components: { RegionPicker },
  data() {
    return {
      allRoles: ROLES_LIST,
      name: {
        val: "",
        isValid: true,
      },
      lastname: {
        val: "",
        isValid: true,
      },
      email: {
        val: "",
        isValid: true,
      },
      phone: {
        val: "",
        isValid: true,
      },
      extensionPhone: {
        val: null,
        isValid: true,
      },
      id: null,
      isLoading: false,
      formIsValid: false,
      role: null,
      displayConfirmation: false,
      selectedGroupedCities: {
        val: [],
        isValid: true,
      },
      selectedCompany: null,
      companiesForProfile: [],
    };
  },
  async created() {
    const userData = this.userData;
    if (userData.id) {
      // load from another
      this.id = userData.id;
      this.name.val = userData.firstname;
      this.lastname.val = userData.lastname;
      this.email.val = userData.email;
      this.phone.val = userData.phone;
      this.role = userData.role;
      if (this.role === "OPERATOR") {
        this.extensionPhone.val = userData.extensionPhone;
      }
      if (this.role === "SALES_MANAGER" && userData?.companies) {
        this.companiesForProfile = userData?.companies;
      }
      await this.getRegions(this.id);
    }
  },
  watch: {
    userData(newValue) {
      this.id = newValue.id;
      this.name.val = newValue.firstname;
      this.lastname.val = newValue.lastname;
      this.email.val = newValue.email;
      this.phone.val = newValue.phone;
      this.role = newValue.role;
      if (this.role === "OPERATOR") {
        this.extensionPhone.val = newValue.extensionPhone;
      }
      if (this.role === "SALES_MANAGER") {
        this.companiesForProfile = newValue.companies;
      }
    },
    selectedCompany: function (newValue, oldValue) {
      if (newValue) {
        this.companiesForProfile.push(newValue);
        this.selectedCompany = null;
      }
    },
  },
  computed: {
    companies() {
      return this.$store.getters["myprofiles/clients"];
    },
    permissions() {
      return this.$store.getters.getPermissions;
    },
    userData() {
      return this.$store.getters.userData;
    },
  },
  methods: {
    async getRegions(id) {
      try {
        this.selectedGroupedCities.val = [];
        const response = await fetch(`${environment.apiUrl}/users/${id}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
            "Content-Type": "application/json",
          },
        });

        const responseData = await response.json();

        if (responseData?.regions) {
          this.selectedGroupedCities.val = responseData.regions;
        }
      } catch (err) {
        console.log(err);
      }
    },
    clearValidity(input) {
      this[input].isValid = true;
    },
    openConfirm() {
      this.displayConfirmation = true;
    },
    closeConfirmation() {
      this.displayConfirmation = false;
    },
    closeConfirmationAndSelect() {
      this.closeConfirmation();
      this.changePassword();
    },
    validateForm() {
      this.formIsValid = true;
      this.name.isValid = true;
      this.lastname.isValid = true;
      this.email.isValid = true;
      this.phone.isValid = true;
      this.extensionPhone.isValid = true;
      if (!this.name.val) {
        this.name.isValid = false;
        this.formIsValid = false;
      }
      if (!this.lastname.val) {
        this.lastname.isValid = false;
        this.formIsValid = false;
      }
      if (!this.phone.val) {
        this.phone.isValid = false;
        this.formIsValid = false;
      }
    },
    async submitForm() {
      this.validateForm();

      if (!this.formIsValid) {
        return;
      }

      this.isLoading = true;

      const actionPayload = {
        id: this.id,
        firstname: this.name.val,
        lastname: this.lastname.val,
        email: this.email.val,
        phone: this.phone.val,
        role: this.role,
        regions: this.selectedGroupedCities.val,
      };

      if (this.role === "OPERATOR") {
        actionPayload.extensionPhone = this.extensionPhone.val;
      }

      try {
        await this.$store.dispatch("myprofiles/updateUser", actionPayload);

        await this.$store.dispatch("getUserMe");

        let userRegions = this.$store.getters["userRegions"];

        await this.$store.commit(
          "setCurrentUserRegions",
          this.$store.getters["selectedUserRegions"].filter((region) => {
            return userRegions.some((elem) => {
              return region.uid === elem.uid;
            });
          })
        );

        window.dispatchEvent(
          new CustomEvent("reloadMultiselectAfterPickRegions")
        );

        this.$toast.add({
          severity: "success",
          summary: "",
          detail: `Профиль обновлён!`,
          life: 6000,
        });
      } catch (err) {
        const error = err.message || "Не получилось обновить профиль";
        this.$toast.add({
          severity: "error",
          summary: "Ошибка при обновлении профиля!",
          detail: error,
          life: 6000,
        });
      }

      this.isLoading = false;
    },
    async changePassword() {
      this.isLoading = true;
      try {
        const response = await fetch(
          `${environment.apiUrl}/password/get_password`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({}),
          }
        );

        if (response.status !== 200) {
          const responseData = await response.json();
          throw new Error(responseData.message || "Ошибка смены пароля!");
        }

        this.$toast.add({
          severity: "success",
          summary: "",
          detail: `Пароль обновлён!`,
          life: 6000,
        });
      } catch (err) {
        this.error = err.message || "Не получилось сменить пароль";
        // console.error("error!", this.error);
        this.$toast.add({
          severity: "error",
          summary: "Ошибка при смене пароля!",
          detail: this.error,
          life: 6000,
        });
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
